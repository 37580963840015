
import { Component, Vue } from 'vue-property-decorator';
import workspaceModule from '@/store/modules/workspaceModule';
import { getComponent } from '@/utils/helpers';
import scheduleModule from '@/store/modules/scheduleModule';
import accountModule from '@/store/modules/accountModule';
import Multiselect from 'vue-multiselect';
import schedule from '@/router/routes/schedule';

@Component({
  components: {
	  Dropdown: () => getComponent('common/Dropdown'),
	Multiselect,
  },
})
export default class PersonnelScheduleAddAssetRowModal extends Vue {
	// allAssetTypes: any;
	assetTypeSelected = []
	assetSelected = []
	assetTypeOptions = [{text: "Well", value: "Well"},
	{text: "Pad", value: "Pad"},
	{text: "Route", value: "Route"},
	{text: "Area", value: "Area"}]
	dropdownTypeShowing = ''
	dropdownLocation: any[] = []


	didLoadAssets(allAssetTypes) {
		if (this.assetTypeSelected.length == 0) return false
		if (allAssetTypes[this.assetTypeSelected[0]['value'] + "s"] == undefined) return false
		return allAssetTypes[this.assetTypeSelected[0]['value'] + "s"].length > 0
	}


	getAssetsByType(type) {

		var existing_assets: any[] = []
		
		for (var x = 0; x < scheduleModule.totalScheduledRowComponents.length; x++) {
			existing_assets.push(scheduleModule.totalScheduledRowComponents[x].title_row.selection_name)
		}

		if (type == "Well") {
			var wells: any = []
			var parsed_wells = JSON.parse(this.allAssetTypes["Wells"])
			for (var x = 0; x < parsed_wells.length; x++) {
				if (existing_assets.indexOf(parsed_wells[x]) > -1) {
					wells.push({text: parsed_wells[x], value: parsed_wells[x], disabled: true})
				} else {
					wells.push({text: parsed_wells[x], value: parsed_wells[x]})
				}
				
			}
			return wells
		}
		if (type == "Pad") {
			var pads: any = []
			var parsed_pads = JSON.parse(this.allAssetTypes["Pads"])
			for (var x = 0; x < parsed_pads.length; x++) {

				if (existing_assets.indexOf(parsed_pads[x]) > -1) {
					pads.push({text: parsed_pads[x], value: parsed_pads[x], disabled: true})
				} else {
					pads.push({text: parsed_pads[x], value: parsed_pads[x]})
				}

			}
			return pads
		}
		if (type == "Route") {
			var routes: any = []
			var parsed_routes = JSON.parse(this.allAssetTypes["Routes"])
			for (var x = 0; x < parsed_routes.length; x++) {

				if (existing_assets.indexOf(parsed_routes[x]) > -1) {
					routes.push({text: parsed_routes[x], value: parsed_routes[x], disabled: true})
				} else {
					routes.push({text: parsed_routes[x], value: parsed_routes[x]})
				}


			}
			return routes
		}
		if (type == "Area") {
			var areas: any = []
			var parsed_areas = JSON.parse(this.allAssetTypes["Areas"])
			for (var x = 0; x < parsed_areas.length; x++) {

				if (existing_assets.indexOf(parsed_areas[x]) > -1) {
					areas.push({text: parsed_areas[x], value: parsed_areas[x], disabled: true})
				} else {
					areas.push({text: parsed_areas[x], value: parsed_areas[x]})
				}

			}
			return areas
		}
		return []

	}

	get allAssetTypes() {
		return scheduleModule.operatorAssetsOutput
	}


  async created() {
	  await scheduleModule.getOperatorAssets()

  }

  get isSavingAddNewSchedule() {
	  return scheduleModule.isSavingAddNewSchedule
  }

  addButtonClicked() {
	  // @ts-ignore
	  this.$emit('add-row-button-clicked', this.assetTypeSelected[0].value, this.assetSelected[0].value)
  }

  hideDropdown() {
	  this.dropdownLocation = []
  }

  didSelectDropdownOption() {
	  this.dropdownLocation = []
  }


	didSelectAssetTypeDropdown(e) {
		const rect = e.srcElement.getBoundingClientRect()
		this.dropdownLocation = [rect.x - 0, rect.y + 38]
		this.dropdownTypeShowing = "AssetType"
	}

	didSelectAssetDropdown(e) {
		if (this.allAssetTypes.length == 0) return
		const rect = e.srcElement.getBoundingClientRect()
		this.dropdownLocation = [rect.x - 0, rect.y + 38]
		this.dropdownTypeShowing = "Asset"
	}





  closeButtonClicked() {
	  this.$emit("close-add-new-table-popup")
  }


}

